export { matchers } from './client-matchers.js';

export const components = [
	() => import("../../src/routes/__layout.svelte"),
	() => import("../../src/routes/__error.svelte"),
	() => import("../../src/routes/[lang]/__layout.svelte"),
	() => import("../../src/routes/[lang]/order/[orderId]/__layout.svelte"),
	() => import("../../src/routes/[lang]/book/[orderId]/contact.svelte"),
	() => import("../../src/routes/[lang]/book/[orderId]/extra.svelte"),
	() => import("../../src/routes/[lang]/book/[orderId]/from.svelte"),
	() => import("../../src/routes/[lang]/book/[orderId]/furnitures/estimateOptions.svelte"),
	() => import("../../src/routes/[lang]/book/[orderId]/furnitures/index.svelte"),
	() => import("../../src/routes/[lang]/book/[orderId]/furnitures/list.svelte"),
	() => import("../../src/routes/[lang]/book/[orderId]/furnitures/special.svelte"),
	() => import("../../src/routes/[lang]/book/[orderId]/summary.svelte"),
	() => import("../../src/routes/[lang]/book/[orderId]/to.svelte"),
	() => import("../../src/routes/[lang]/book/index.svelte"),
	() => import("../../src/routes/[lang]/order/[orderId]/complaint/index.svelte"),
	() => import("../../src/routes/[lang]/order/[orderId]/confirm/index.svelte"),
	() => import("../../src/routes/[lang]/order/[orderId]/contact/index.svelte"),
	() => import("../../src/routes/[lang]/order/[orderId]/index.svelte"),
	() => import("../../src/routes/[lang]/order/[orderId]/jobs.svelte"),
	() => import("../../src/routes/[lang]/order/[orderId]/payments/[invoiceId].svelte"),
	() => import("../../src/routes/[lang]/order/[orderId]/payments/index.svelte"),
	() => import("../../src/routes/[lang]/order/index.svelte"),
	() => import("../../src/routes/index.svelte")
];

export const dictionary = {
	"": [[0, 22], [1]],
	"[lang]/book": [[0, 2, 13], [1], 1],
	"[lang]/order": [[0, 2, 21], [1]],
	"[lang]/order/[orderId]": [[0, 2, 3, 17], [1]],
	"[lang]/book/[orderId]/contact": [[0, 2, 4], [1], 1],
	"[lang]/book/[orderId]/extra": [[0, 2, 5], [1], 1],
	"[lang]/book/[orderId]/from": [[0, 2, 6], [1], 1],
	"[lang]/book/[orderId]/furnitures": [[0, 2, 8], [1], 1],
	"[lang]/book/[orderId]/summary": [[0, 2, 11], [1], 1],
	"[lang]/book/[orderId]/to": [[0, 2, 12], [1], 1],
	"[lang]/order/[orderId]/complaint": [[0, 2, 3, 14], [1], 1],
	"[lang]/order/[orderId]/confirm": [[0, 2, 3, 15], [1], 1],
	"[lang]/order/[orderId]/contact": [[0, 2, 3, 16], [1], 1],
	"[lang]/order/[orderId]/jobs": [[0, 2, 3, 18], [1], 1],
	"[lang]/order/[orderId]/payments": [[0, 2, 3, 20], [1], 1],
	"[lang]/book/[orderId]/furnitures/estimateOptions": [[0, 2, 7], [1], 1],
	"[lang]/book/[orderId]/furnitures/list": [[0, 2, 9], [1], 1],
	"[lang]/book/[orderId]/furnitures/special": [[0, 2, 10], [1], 1],
	"[lang]/order/[orderId]/payments/[invoiceId]": [[0, 2, 3, 19], [1], 1]
};